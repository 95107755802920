<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <section class="ecommerce-application">
        <!-- Chat Content -->
        <b-row>
            <b-col cols="12">
                <b-form @submit.prevent="sendText" class="w-100">
                    <b-card class="w-100">
                        <b-row>
                            <b-col cols="12" md="3">
                                <b-form-group>
                                    <label for="lang">{{ $t('Language') }}</label>
                                    <b-select id="lang" :options="langOpts" v-model="form.lang" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group>
                                    <label for="voice">{{ $t('Voice') }}</label>
                                    <b-select id="voice" :options="voiceOpts" v-model="form.voice" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group>
                                    <label for="speed">{{ $t('Speed') }}</label>
                                    <b-select id="speed" :options="speedOpts" v-model="form.speed" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group>
                                    <label for="break">{{ $t('Break') }}</label>
                                    <b-select id="break" :options="breakOpts" v-model="form.break" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="title">{{ $t('Title') }}</label>
                                    <b-input id="title" :required="true" name="title" :disabled="loading" v-model="form.title" :placeholder="$t('Enter Title')" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="text">{{ $t('Text') }}</label>
                                    <b-textarea id="text" :required="true" v-model="texts" :disabled="loading" :placeholder="$t('Enter Text')" rows="5"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-button type="submit" variant="primary" :disabled="isEmpty(form.title) || isEmpty(texts) || loading">
                                    {{ $t('Generate') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-form>
            </b-col>
            <b-col cols="12" class="mt-3 w-100">
                <b-card>
                    <good-data-table ref="savedT2STable" :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" :data-column="'response.responseData.data'"
                        :api-endpoint="'/ai/saved-t2s'"
                        @add-new-info="toggelModal = true" :hideExportBtns="true" 
                    />
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>
  
<script>
import { langOpts, allVoiceOpts, breakOpts, speedOpts } from './text-to-speech-dd'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities'
import { showErrorNotification, showLoader, showSuccessNotification, hideLoader, showDangerNotification } from '@/@core/comp-functions/ui/app'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
    name: 'AIS2T',
    components: {
        GoodDataTable
    },
    props: {
        
    },

    data: () => ({
        loading: false,
        form: {
            title: null,
            lang: 'en-US',
            voice: null,
            speed: 'medium',
            break: 1,
        },
        texts: null,
        langOpts, breakOpts, speedOpts,
        audioPrefix: `${jwtDefaultConfig.baseEndpoint}/storage/`
    }),

    computed: {
        voiceOpts: {
            get(){
                if(isEmpty(this.form.lang)) return []
                return allVoiceOpts[this.form.lang]
            }
        },
        isMobileView: {
            get(){
                return this.$store.getters['app/windowSize'] <= 767
            }
        },
        fields: {
            get() {
                return [
                    {
                        field: 'title',
                        label: 'Title',
                        align: 'center',
                    },
                    {
                        field: 'audio',
                        label: 'Audio',
                        align: 'center',
                        useHtml: true,
                        renderer: props => {
                            return `<audio src="${this.audioPrefix}${props.audio}" controls />`;
                        },
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        align: 'center',
                        sortable: false,
                        type: 'dropdown',
                        actions: [
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('download'),
                                text: 'Download',
                                action: props => {
                                    this.$helpers.downloadFile(this, `${this.audioPrefix}${props.audio}`)
                                },
                            },
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('delete'),
                                text: 'Delete',
                                action: props => {
                                    this.$swal({
                                        title: this.$t('Are you sure? You want to delete this audio?'),
                                        iconHtml: this.$helpers.swalIcon('alert.png'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('Yes'),
                                        cancelButtonText: this.$t('No'),
                                        customClass: {
                                            confirmButton: 'btn btn-success',
                                            cancelButton: 'btn btn-danger ml-1',
                                            icon: 'border-0',
                                        },
                                        buttonsStyling: false,
                                    }).then(result => {
                                        if (result.value) {
                                            this.deleteItem(props)
                                        }
                                    })
                                },
                            },
                        ],
                    },
                ].filter(i => {
                    if(this.isMobileView){
                        return i.field != 'audio'
                    }
                    return true
                })
            }
        }

    },
    mounted() {
        this.setVoice()
    },
    methods: {
        isEmpty,
        ...mapActions('ai', [
            'aiTextToSpeech',
            'getSavedT2S',
            'deleteSavedT2S',
        ]),
        setVoice(){
            if(!isEmpty(this.voiceOpts)){
                const voiceOpt = this.voiceOpts.find((i,idx) =>idx === 0)
                if(!isEmpty(voiceOpt)){
                    this.form.voice = voiceOpt.value
                }
            }
        },
        resetForm(){
            this.form = {
                title: null,
                lang: 'en-US',
                voice: null,
                speed: 'medium',
                break: 1,
            }
        },
        sendText(){
            this.errors = []
            this.loading = true
            showLoader()
            this.loading = true
            const payLoad = this.form
            payLoad[`texts[]`] = this.texts
            this.aiTextToSpeech(payLoad).then(r => {
                if(typeof r === 'string'){
                    showDangerNotification(this, r)
                }else{
                    if (r._statusCode === 200) {
                        this.$refs.savedT2STable.loadItems()
                        this.resetForm()
                    }else if (r._statusCode === 201) {
                        showErrorNotification(this, r.message)
                    }else {
                        showErrorNotification(this, "Something went wrong!")
                    }
                }
                this.loading = false
                hideLoader()
            }).catch((e) => {
                if(e.response){
                    const { status, data } = e.response
                    if(data?.message && status === 500){
                        const { message } = data
                        showErrorNotification(this, message)
                    }
                }else{
                    showErrorNotification(this, "Something went wrong!")
                }
                this.loading = false
                hideLoader()
            });
        },
        copyToClipBoard(copy_input_id) {
            let copyInput = document.querySelector('#'+copy_input_id);
            copyInput.select();
            try {
                if(document.execCommand('copy')){
                    showSuccessNotification(this, 'Copied to clipboard');
                }else{
                    showErrorNotification(this, "Failed to copy");
                }
            } catch (err) {
                showErrorNotification(this, "Failed to copy");
            }
            window.getSelection().removeAllRanges()
        },
        deleteItem(props){
            this.loading = true
            showLoader()
            this.deleteSavedT2S(props.id).then(r => {
                if (r === true) {
                    this.resetForm()
                    this.$refs.savedT2STable.loadItems()
                }else {
                    showErrorNotification(this, r || "Something went wrong!")
                }
                this.loading = false
                hideLoader()
            }).catch(e => {
                this.loading = false
                hideLoader()
            })
        }
    },
    watch: {
        'form.lang': {
            handler(nv){
                if(!isEmpty(nv)){
                    this.setVoice()
                }
            },
            deep: true,
            immediate: true
        }
    }

}
</script>