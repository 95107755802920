export const langOpts = [
    { value: 'af-ZA', text: 'Afrikaans (South Africa)' },
    { value: 'ar-XA', text: 'Arabic' },
    { value: 'ar-EG', text: 'Arabic (Egypt)' },
    { value: 'ar-SA', text: 'Arabic (Saudi Arabia)' },
    { value: 'bn-IN', text: 'Bengali (India)' },
    { value: 'bg-BG', text: 'Bulgarian (Bulgaria)' },
    { value: 'ca-ES', text: 'Catalan (Spain)' },
    { value: 'zh-HK', text: 'Chinese (Cantonese)' },
    { value: 'cmn-CN', text: 'Chinese (Mandarin)' },
    { value: 'zh-CN', text: 'Chinese (M. Simplified)' },
    { value: 'zh-TW', text: 'Chinese (Taiwanese M.)' },
    { value: 'hr-HR', text: 'Croatian (Croatia)' },
    { value: 'cs-CZ', text: 'Czech (Czech Republic)' },
    { value: 'da-DK', text: 'Danish (Denmark)' },
    { value: 'nl-BE', text: 'Dutch (Belgium)' },
    { value: 'nl-NL', text: 'Dutch (Netherlands)' },
    { value: 'en-AU', text: 'English (Australia)' },
    { value: 'en-CA', text: 'English (Canada)' },
    { value: 'en-HK', text: 'English (Hongkong)' },
    { value: 'en-IN', text: 'English (India)' },
    { value: 'en-IE', text: 'English (Ireland)' },
    { value: 'en-NZ', text: 'English (New Zealand)' },
    { value: 'en-PH', text: 'English (Philippines)' },
    { value: 'en-SG', text: 'English (Singapore)' },
    { value: 'en-ZA', text: 'English (South Africa)' },
    { value: 'en-GB', text: 'English (UK)' },
    { value: 'en-US', text: 'English (USA)' },
    { value: 'et-EE', text: 'Estonian (Estonia)' },
    { value: 'fil-PH', text: 'Filipino (Philippines)' },
    { value: 'fi-FI', text: 'Finnish (Finland)' },
    { value: 'fr-BE', text: 'French (Belgium)' },
    { value: 'fr-FR', text: 'French (France)' },
    { value: 'fr-CA', text: 'French (Canada)' },
    { value: 'fr-CH', text: 'French (Switzerland)' },
    { value: 'de-AT', text: 'German (Austria)' },
    { value: 'de-DE', text: 'German (Germany)' },
    { value: 'de-CH', text: 'German (Switzerland)' },
    { value: 'el-GR', text: 'Greek (Greece)' },
    { value: 'gu-IN', text: 'Gujarati (India)' },
    { value: 'he-IL', text: 'Hebrew (Israel)' },
    { value: 'hi-IN', text: 'Hindi (India)' },
    { value: 'hu-HU', text: 'Hungarian (Hungary)' },
    { value: 'is-IS', text: 'Icelandic (Iceland)' },
    { value: 'id-ID', text: 'Indonesian (Indonesia)' },
    { value: 'ga-IE', text: 'Irish (Ireland)' },
    { value: 'it-IT', text: 'Italian (Italy)' },
    { value: 'ja-JP', text: 'Japanese (Japan)' },
    { value: 'kn-IN', text: 'Kannada (India)' },
    { value: 'ko-KR', text: 'Korean (South Korea)' },
    { value: 'lv-LV', text: 'Latvian (Latvia)' },
    { value: 'lt-LT', text: 'Lithuanian (Lithuania)' },
    { value: 'ml-IN', text: 'Malayalam (India)' },
    { value: 'ms-MY', text: 'Malay (Malaysia)' },
    { value: 'mt-MT', text: 'Maltese (Malta)' },
    { value: 'mr-IN', text: 'Marathi (India)' },
    { value: 'nb-NO', text: 'Norwegian (Norway)' },
    { value: 'pl-PL', text: 'Polish (Poland)' },
    { value: 'pt-BR', text: 'Portuguese (Brazil)' },
    { value: 'pt-PT', text: 'Portuguese (Portugal)' },
    { value: 'ro-RO', text: 'Romanian (Romania)' },
    { value: 'ru-RU', text: 'Russian (Russia)' },
    { value: 'sr-RS', text: 'Serbian (Serbia)' },
    { value: 'sk-SK', text: 'Slovak (Slovakia)' },
    { value: 'sl-SI', text: 'Slovenian (Slovenia)' },
    { value: 'es-AR', text: 'Spanish (Argentina)' },
    { value: 'es-CO', text: 'Spanish (Colombia)' },
    { value: 'es-ES', text: 'Spanish (Spain)' },
    { value: 'es-MX', text: 'Spanish (Mexico)' },
    { value: 'es-US', text: 'Spanish (USA)' },
    { value: 'sw-KE', text: 'Swahili (Kenya)' },
    { value: 'sv-SE', text: 'Swedish (Sweden)' },
    { value: 'ta-IN', text: 'Tamil (India)' },
    { value: 'te-IN', text: 'Telugu (India)' },
    { value: 'th-TH', text: 'Thai (Thailand)' },
    { value: 'tr-TR', text: 'Turkish (Turkey)' },
    { value: 'uk-UA', text: 'Ukrainian (Ukraine)' },
    { value: 'ur-PK', text: 'Urdu (Pakistan)' },
    { value: 'vi-VN', text: 'Vietnamese (Vietnam)' },
    { value: 'cy-GB', text: 'Welsh (Wales)' },
    { value: 'am-ET', text: 'Amharic (Ethiopia)' },
    { value: 'ar-DZ', text: 'Arabic (Algeria)' },
    { value: 'ar-BH', text: 'Arabic (Bahrain)' },
    { value: 'ar-IQ', text: 'Arabic (Iraq)' },
    { value: 'ar-JO', text: 'Arabic (Jordan)' },
    { value: 'ar-KW', text: 'Arabic (Kuwait)' },
    { value: 'ar-LY', text: 'Arabic (Libya)' },
    { value: 'ar-MA', text: 'Arabic (Morocco)' },
    { value: 'ar-QA', text: 'Arabic (Qatar)' },
    { value: 'ar-SY', text: 'Arabic (Syria)' },
    { value: 'ar-TN', text: 'Arabic (Tunisia)' },
    { value: 'ar-AE', text: 'Arabic (UAE)' },
    { value: 'ar-YE', text: 'Arabic (Yemen)' },
    { value: 'bn-BD', text: 'Bangla (Bangladesh)' },
    { value: 'my-MM', text: 'Burmese (Myanmar)' },
    { value: 'en-KE', text: 'English (Kenya)' },
    { value: 'en-NG', text: 'English (Nigeria)' },
    { value: 'en-TZ', text: 'English (Tanzania)' },
    { value: 'gl-ES', text: 'Galician (Spain)' },
    { value: 'jv-ID', text: 'Javanese (Indonesia)' },
    { value: 'fa-IR', text: 'Persian (Iran)' },
    { value: 'km-KH', text: 'Khmer (Cambodia)' },
    { value: 'so-SO', text: 'Somali (Somalia)' },
    { value: 'es-BO', text: 'Spanish (Bolivia)' },
    { value: 'es-CL', text: 'Spanish (Chile)' },
    { value: 'es-CR', text: 'Spanish (Costa Rica)' },
    { value: 'es-CU', text: 'Spanish (Cuba)' },
    { value: 'es-DO', text: 'Spanish (Dominican Republic)' },
    { value: 'es-EC', text: 'Spanish (Ecuador)' },
    { value: 'es-SV', text: 'Spanish (El Salvador)' },
    { value: 'es-GQ', text: 'Spanish (Equatorial Guinea)' },
    { value: 'es-GT', text: 'Spanish (Guatemala)' },
    { value: 'es-HN', text: 'Spanish (Honduras)' },
    { value: 'es-NI', text: 'Spanish (Nicaragua)' },
    { value: 'es-PA', text: 'Spanish (Panama)' },
    { value: 'es-PY', text: 'Spanish (Paraguay)' },
    { value: 'es-PE', text: 'Spanish (Peru)' },
    { value: 'es-PR', text: 'Spanish (Puerto Rico)' },
    { value: 'es-UY', text: 'Spanish (Uruguay)' },
    { value: 'es-VE', text: 'Spanish (Venezuela)' },
    { value: 'su-ID', text: 'Sundanese (Indonesia)' },
    { value: 'sw-TZ', text: 'Swahili (Tanzania)' },
    { value: 'ta-SG', text: 'Tamil (Singapore)' },
    { value: 'ta-LK', text: 'Tamil (Sri Lanka)' },
    { value: 'ur-IN', text: 'Urdu (India)' },
    { value: 'uz-UZ', text: 'Uzbek (Uzbekistan)' },
    { value: 'zu-ZA', text: 'Zulu (South Africa)' },
    { value: 'kk-KZ', text: 'Kazakh (Kazakhstan)' },
    { value: 'lo-LA', text: 'Lao (Laos)' },
    { value: 'mk-MK', text: 'Macedonian (Macedonia)' },
    { value: 'ps-AF', text: 'Pashto (Afghanistan)' },
    { value: 'si-LK', text: 'Sinhala (Sri Lanka)' },
    { value: 'pa-IN', text: 'Punjabi (India)' },
    { value: 'az-AZ', text: 'Azerbaijani (Azerbaijan)' },
    { value: 'ar-LB', text: 'Arabic (Lebanon)' },
    { value: 'sq-AL', text: 'Albanian (Albania)' },
    { value: 'ka-GE', text: 'Georgian (Georgia)' },
    { value: 'mn-MN', text: 'Mongolian (Mongolia)' },
    { value: 'ne-NP', text: 'Nepali (Nepal)' },
    { value: 'bs-BA', text: 'Bosnian (Bosnia and Herzegovina)' },
    { value: 'ar-OM', text: 'Arabic (Oman)' },
    { value: 'ta-MY', text: 'Tamil (Malaysia)' },
];

export const allVoiceOpts = {
    "ar-EG": [
        {
            value: "ar-EG-SalmaNeural",
            text: "Rene - Female"
        },
        {
            value: "ar-EG-ShakirNeural",
            text: "Fae - Male"
        }
    ],
    "ar-SA": [
        {
            value: "ar-SA-ZariyahNeural",
            text: "Elmo - Female"
        },
        {
            value: "ar-SA-HamedNeural",
            text: "Eldora - Male"
        }
    ],
    "bg-BG": [
        {
            value: "bg-BG-KalinaNeural",
            text: "Remington - Female"
        },
        {
            value: "bg-BG-BorislavNeural",
            text: "Delpha - Male"
        }
    ],
    "ca-ES": [
        {
            value: "ca-ES-AlbaNeural",
            text: "Dedrick - Female"
        },
        {
            value: "ca-ES-JoanaNeural",
            text: "Oral - Female"
        },
        {
            value: "ca-ES-EnricNeural",
            text: "Allison - Male"
        }
    ],
    "zh-HK": [
        {
            value: "zh-HK-HiuGaaiNeural",
            text: "Moises - Female"
        },
        {
            value: "zh-HK-HiuMaanNeural",
            text: "Wilton - Female"
        },
        {
            value: "zh-HK-WanLungNeural",
            text: "Bessie - Male"
        }
    ],
    "zh-CN": [
        {
            value: "zh-CN-XiaoxiaoNeural",
            text: "Freeman - Female"
        },
        {
            value: "zh-CN-XiaoyouNeural",
            text: "Uriel - Female"
        },
        {
            value: "zh-CN-XiaomoNeural",
            text: "Kaley - Female"
        },
        {
            value: "zh-CN-XiaoxuanNeural",
            text: "Sigurd - Female"
        },
        {
            value: "zh-CN-XiaohanNeural",
            text: "Jess - Female"
        },
        {
            value: "zh-CN-XiaoruiNeural",
            text: "Jettie - Female"
        },
        {
            value: "zh-CN-YunyangNeural",
            text: "Velma - Male"
        },
        {
            value: "zh-CN-YunyeNeural",
            text: "Norene - Male"
        },
        {
            value: "zh-CN-YunxiNeural",
            text: "Suzanne - Male"
        },
        {
            value: "zh-CN-XiaochenNeural",
            text: "Gussie - Female"
        },
        {
            value: "zh-CN-XiaoyanNeural",
            text: "Zane - Female"
        },
        {
            value: "zh-CN-XiaoshuangNeural",
            text: "Valentin - Female"
        },
        {
            value: "zh-CN-XiaoqiuNeural",
            text: "Herminio - Female"
        }
    ],
    "zh-TW": [
        {
            value: "zh-TW-HsiaoChenNeural",
            text: "Wilburn - Female"
        },
        {
            value: "zh-TW-HsiaoYuNeural",
            text: "Kelton - Female"
        },
        {
            value: "zh-TW-YunJheNeural",
            text: "Joannie - Male"
        }
    ],
    "hr-HR": [
        {
            value: "hr-HR-GabrijelaNeural",
            text: "Emerald - Female"
        },
        {
            value: "hr-HR-SreckoNeural",
            text: "Chloe - Male"
        }
    ],
    "cs-CZ": [
        {
            value: "cs-CZ-VlastaNeural",
            text: "Carlo - Female"
        },
        {
            value: "cs-CZ-AntoninNeural",
            text: "Carley - Male"
        }
    ],
    "da-DK": [
        {
            value: "da-DK-ChristelNeural",
            text: "Cristina - Female"
        },
        {
            value: "da-DK-JeppeNeural",
            text: "Arlie - Male"
        }
    ],
    "nl-BE": [
        {
            value: "nl-BE-DenaNeural",
            text: "Mustafa - Female"
        },
        {
            value: "nl-BE-ArnaudNeural",
            text: "Kelsi - Male"
        }
    ],
    "nl-NL": [
        {
            value: "nl-NL-ColetteNeural",
            text: "Waldo - Female"
        },
        {
            value: "nl-NL-FennaNeural",
            text: "Emery - Female"
        },
        {
            value: "nl-NL-MaartenNeural",
            text: "Amiya - Male"
        }
    ],
    "en-AU": [
        {
            value: "en-AU-NatashaNeural",
            text: "Buford - Female"
        },
        {
            value: "en-AU-WilliamNeural",
            text: "Dayna - Male"
        }
    ],
    "en-CA": [
        {
            value: "en-CA-ClaraNeural",
            text: "King - Female"
        },
        {
            value: "en-CA-LiamNeural",
            text: "Mariela - Male"
        }
    ],
    "en-HK": [
        {
            value: "en-HK-YanNeural",
            text: "Devante - Female"
        },
        {
            value: "en-HK-SamNeural",
            text: "Mafalda - Male"
        }
    ],
    "en-IN": [
        {
            value: "en-IN-NeerjaNeural",
            text: "Dudley - Female"
        },
        {
            value: "en-IN-PrabhatNeural",
            text: "Heloise - Male"
        }
    ],
    "en-IE": [
        {
            value: "en-IE-EmilyNeural",
            text: "Avery - Female"
        },
        {
            value: "en-IE-ConnorNeural",
            text: "Pearlie - Male"
        }
    ],
    "en-NZ": [
        {
            value: "en-NZ-MollyNeural",
            text: "Demond - Female"
        },
        {
            value: "en-NZ-MitchellNeural",
            text: "Ashtyn - Male"
        }
    ],
    "en-PH": [
        {
            value: "en-PH-RosaNeural",
            text: "Elliott - Female"
        },
        {
            value: "en-PH-JamesNeural",
            text: "Kimberly - Male"
        }
    ],
    "en-SG": [
        {
            value: "en-SG-LunaNeural",
            text: "Eli - Female"
        },
        {
            value: "en-SG-WayneNeural",
            text: "Alysa - Male"
        }
    ],
    "en-ZA": [
        {
            value: "en-ZA-LeahNeural",
            text: "Matt - Female"
        },
        {
            value: "en-ZA-LukeNeural",
            text: "Vincenza - Male"
        }
    ],
    "en-GB": [
        {
            value: "en-GB-LibbyNeural",
            text: "Allen - Female"
        },
        {
            value: "en-GB-RyanNeural",
            text: "Linda - Male"
        },
        {
            value: "en-GB-SoniaNeural",
            text: "Greg - Female"
        },
        {
            value: "en-GB-AbbiNeural",
            text: "Emmet - Female"
        },
        {
            value: "en-GB-BellaNeural",
            text: "Orrin - Female"
        },
        {
            value: "en-GB-HollieNeural",
            text: "Evans - Female"
        },
        {
            value: "en-GB-OliviaNeural",
            text: "Ryley - Female"
        },
        {
            value: "en-GB-MaisieNeural",
            text: "Burdette - Female (child)"
        },
        {
            value: "en-GB-AlfieNeural",
            text: "Adrianna - Male"
        },
        {
            value: "en-GB-ElliotNeural",
            text: "Else - Male"
        },
        {
            value: "en-GB-EthanNeural",
            text: "Lulu - Male"
        },
        {
            value: "en-GB-NoahNeural",
            text: "Amiya - Male"
        },
        {
            value: "en-GB-OliverNeural",
            text: "Dayana - Male"
        },
        {
            value: "en-GB-ThomasNeural",
            text: "Ivy - Male"
        }
    ],
    "en-US": [
        {
            value: "en-US-AriaNeural",
            text: "Leonardo - Female"
        },
        {
            value: "en-US-JennyNeural",
            text: "Eli - Female"
        },
        {
            value: "en-US-GuyNeural",
            text: "Ivah - Male"
        },
        {
            value: "en-US-AmberNeural",
            text: "Coby - Female"
        },
        {
            value: "en-US-AshleyNeural",
            text: "Vito - Female"
        },
        {
            value: "en-US-CoraNeural",
            text: "Keeley - Female"
        },
        {
            value: "en-US-ElizabethNeural",
            text: "Alexie - Female"
        },
        {
            value: "en-US-MichelleNeural",
            text: "Geo - Female"
        },
        {
            value: "en-US-MonicaNeural",
            text: "Hayden - Female"
        },
        {
            value: "en-US-SaraNeural",
            text: "Alberto - Female"
        },
        {
            value: "en-US-AnaNeural",
            text: "Celestine - Female (child)"
        },
        {
            value: "en-US-BrandonNeural",
            text: "Rosetta - Male"
        },
        {
            value: "en-US-ChristopherNeural",
            text: "Shakira - Male"
        },
        {
            value: "en-US-EricNeural",
            text: "Dawn - Male"
        },
        {
            value: "en-US-JacobNeural",
            text: "Leanne - Male"
        }
    ],
    "et-EE": [
        {
            value: "et-EE-AnuNeural",
            text: "Wilber - Female"
        },
        {
            value: "et-EE-KertNeural",
            text: "Gloria - Male"
        }
    ],
    "fi-FI": [
        {
            value: "fi-FI-NooraNeural",
            text: "Toni - Female"
        },
        {
            value: "fi-FI-SelmaNeural",
            text: "Stephen - Female"
        },
        {
            value: "fi-FI-HarriNeural",
            text: "Candice - Male"
        }
    ],
    "fr-BE": [
        {
            value: "fr-BE-CharlineNeural",
            text: "Stefan - Female"
        },
        {
            value: "fr-BE-GerardNeural",
            text: "Kenya - Male"
        }
    ],
    "fr-CA": [
        {
            value: "fr-CA-SylvieNeural",
            text: "Emmanuel - Female"
        },
        {
            value: "fr-CA-AntoineNeural",
            text: "Alberta - Male"
        },
        {
            value: "fr-CA-JeanNeural",
            text: "Anabelle - Male"
        }
    ],
    "fr-FR": [
        {
            value: "fr-FR-DeniseNeural",
            text: "Kadin - Female"
        },
        {
            value: "fr-FR-HenriNeural",
            text: "Noelia - Male"
        },
        {
            value: "fr-FR-BrigitteNeural",
            text: "Lambert - Female"
        },
        {
            value: "fr-FR-CelesteNeural",
            text: "Antone - Female"
        },
        {
            value: "fr-FR-CoralieNeural",
            text: "Mervin - Female"
        },
        {
            value: "fr-FR-JacquelineNeural",
            text: "Brook - Female"
        },
        {
            value: "fr-FR-JosephineNeural",
            text: "Norbert - Female"
        },
        {
            value: "fr-FR-YvetteNeural",
            text: "Roosevelt - Female"
        },
        {
            value: "fr-FR-EloiseNeural",
            text: "Deanna - Female (child)"
        },
        {
            value: "fr-FR-AlainNeural",
            text: "Mireille - Male"
        },
        {
            value: "fr-FR-ClaudeNeural",
            text: "Willow - Male"
        },
        {
            value: "fr-FR-JeromeNeural",
            text: "Liliane - Male"
        },
        {
            value: "fr-FR-MauriceNeural",
            text: "Mazie - Male"
        },
        {
            value: "fr-FR-YvesNeural",
            text: "Otilia - Male"
        }
    ],
    "fr-CH": [
        {
            value: "fr-CH-ArianeNeural",
            text: "Jamel - Female"
        },
        {
            value: "fr-CH-FabriceNeural",
            text: "Myrtis - Male"
        }
    ],
    "de-AT": [
        {
            value: "de-AT-IngridNeural",
            text: "Dennis - Female"
        },
        {
            value: "de-AT-JonasNeural",
            text: "Felicity - Male"
        }
    ],
    "de-DE": [
        {
            value: "de-DE-KatjaNeural",
            text: "Domenic - Female"
        },
        {
            value: "de-DE-ConradNeural",
            text: "Freda - Male"
        },
        {
            value: "de-DE-AmalaNeural",
            text: "Gus - Female"
        },
        {
            value: "de-DE-ElkeNeural",
            text: "Hilbert - Female"
        },
        {
            value: "de-DE-KlarissaNeural",
            text: "Kamron - Female"
        },
        {
            value: "de-DE-LouisaNeural",
            text: "Giovanni - Female"
        },
        {
            value: "de-DE-MajaNeural",
            text: "Peter - Female"
        },
        {
            value: "de-DE-TanjaNeural",
            text: "Charley - Female"
        },
        {
            value: "de-DE-GiselaNeural",
            text: "Cayla - Female (child)"
        },
        {
            value: "de-DE-BerndNeural",
            text: "Mariane - Male"
        },
        {
            value: "de-DE-ChristophNeural",
            text: "Lorna - Male"
        },
        {
            value: "de-DE-KasperNeural",
            text: "Audreanne - Male"
        },
        {
            value: "de-DE-KillianNeural",
            text: "Carlee - Male"
        },
        {
            value: "de-DE-KlausNeural",
            text: "Noemi - Male"
        },
        {
            value: "de-DE-RalfNeural",
            text: "Marguerite - Male"
        }
    ],
    "de-CH": [
        {
            value: "de-CH-LeniNeural",
            text: "Richie - Female"
        },
        {
            value: "de-CH-JanNeural",
            text: "Chanelle - Male"
        }
    ],
    "el-GR": [
        {
            value: "el-GR-AthinaNeural",
            text: "Heber - Female"
        },
        {
            value: "el-GR-NestorasNeural",
            text: "Nyah - Male"
        }
    ],
    "gu-IN": [
        {
            value: "gu-IN-DhwaniNeural",
            text: "Ford - Female"
        },
        {
            value: "gu-IN-NiranjanNeural",
            text: "Carolina - Male"
        }
    ],
    "he-IL": [
        {
            value: "he-IL-HilaNeural",
            text: "Larry - Female"
        },
        {
            value: "he-IL-AvriNeural",
            text: "Willie - Male"
        }
    ],
    "hi-IN": [
        {
            value: "hi-IN-SwaraNeural",
            text: "Seamus - Female"
        },
        {
            value: "hi-IN-MadhurNeural",
            text: "Marlen - Male"
        }
    ],
    "hu-HU": [
        {
            value: "hu-HU-NoemiNeural",
            text: "Travon - Female"
        },
        {
            value: "hu-HU-TamasNeural",
            text: "Destiny - Male"
        }
    ],
    "id-ID": [
        {
            value: "id-ID-GadisNeural",
            text: "Kristian - Female"
        },
        {
            value: "id-ID-ArdiNeural",
            text: "Laurianne - Male"
        }
    ],
    "ga-IE": [
        {
            value: "ga-IE-OrlaNeural",
            text: "Clair - Female"
        },
        {
            value: "ga-IE-ColmNeural",
            text: "Aryanna - Male"
        }
    ],
    "it-IT": [
        {
            value: "it-IT-ElsaNeural",
            text: "Jaylen - Female"
        },
        {
            value: "it-IT-IsabellaNeural",
            text: "Elton - Female"
        },
        {
            value: "it-IT-DiegoNeural",
            text: "Lacy - Male"
        },
        {
            value: "it-IT-PierinaNeural",
            text: "Fernando - Female"
        },
        {
            value: "it-IT-FabiolaNeural",
            text: "Gaston - Female"
        },
        {
            value: "it-IT-ImeldaNeural",
            text: "Dante - Female"
        },
        {
            value: "it-IT-PalmiraNeural",
            text: "Oscar - Female"
        },
        {
            value: "it-IT-FiammaNeural",
            text: "Samir - Female"
        },
        {
            value: "it-IT-IrmaNeural",
            text: "Gregory - Female"
        },
        {
            value: "it-IT-BenignoNeural",
            text: "Ivah - Male"
        },
        {
            value: "it-IT-CataldoNeural",
            text: "Dolly - Male"
        },
        {
            value: "it-IT-LisandroNeural",
            text: "Bianka - Male"
        },
        {
            value: "it-IT-GianniNeural",
            text: "Krystal - Male"
        },
        {
            value: "it-IT-CalimeroNeural",
            text: "Patience - Male"
        },
        {
            value: "it-IT-RinaldoNeural",
            text: "Verlie - Male"
        }
    ],
    "ja-JP": [
        {
            value: "ja-JP-NanamiNeural",
            text: "Frank - Female"
        },
        {
            value: "ja-JP-KeitaNeural",
            text: "Antonietta - Male"
        }
    ],
    "ko-KR": [
        {
            value: "ko-KR-SunHiNeural",
            text: "Isaac - Female"
        },
        {
            value: "ko-KR-InJoonNeural",
            text: "Sarina - Male"
        }
    ],
    "lv-LV": [
        {
            value: "lv-LV-EveritaNeural",
            text: "Kennedy - Female"
        },
        {
            value: "lv-LV-NilsNeural",
            text: "Angelica - Male"
        }
    ],
    "lt-LT": [
        {
            value: "lt-LT-OnaNeural",
            text: "Raven - Female"
        },
        {
            value: "lt-LT-LeonasNeural",
            text: "Carmela - Male"
        }
    ],
    "ms-MY": [
        {
            value: "ms-MY-YasminNeural",
            text: "Erin - Female"
        },
        {
            value: "ms-MY-OsmanNeural",
            text: "Marian - Male"
        }
    ],
    "mt-MT": [
        {
            value: "mt-MT-GraceNeural",
            text: "Boyd - Female"
        },
        {
            value: "mt-MT-JosephNeural",
            text: "Idell - Male"
        }
    ],
    "mr-IN": [
        {
            value: "mr-IN-AarohiNeural",
            text: "Felipe - Female"
        },
        {
            value: "mr-IN-ManoharNeural",
            text: "Lilyan - Male"
        }
    ],
    "nb-NO": [
        {
            value: "nb-NO-IselinNeural",
            text: "Buford - Female"
        },
        {
            value: "nb-NO-PernilleNeural",
            text: "Cortez - Female"
        },
        {
            value: "nb-NO-FinnNeural",
            text: "Theresia - Male"
        }
    ],
    "pl-PL": [
        {
            value: "pl-PL-AgnieszkaNeural",
            text: "Louie - Female"
        },
        {
            value: "pl-PL-ZofiaNeural",
            text: "Hiram - Female"
        },
        {
            value: "pl-PL-MarekNeural",
            text: "Clemmie - Male"
        }
    ],
    "pt-BR": [
        {
            value: "pt-BR-FranciscaNeural",
            text: "Jocelyn - Female"
        },
        {
            value: "pt-BR-AntonioNeural",
            text: "Eliza - Male"
        },
        {
            value: "pt-BR-DonatoNeural",
            text: "Raegan - Male"
        },
        {
            value: "pt-BR-FabioNeural",
            text: "Allene - Male"
        },
        {
            value: "pt-BR-JulioNeural",
            text: "Kenna - Male"
        },
        {
            value: "pt-BR-NicolauNeural",
            text: "Rhea - Male"
        },
        {
            value: "pt-BR-ValerioNeural",
            text: "Destiny - Male"
        },
        {
            value: "pt-BR-LeticiaNeural",
            text: "Kole - Female"
        },
        {
            value: "pt-BR-BrendaNeural",
            text: "Vern - Female"
        },
        {
            value: "pt-BR-ElzaNeural",
            text: "Louisa - Female"
        },
        {
            value: "pt-BR-ManuelaNeural",
            text: "Manuel - Female"
        },
        {
            value: "pt-BR-GiovannaNeural",
            text: "Leopoldo - Female"
        },
        {
            value: "pt-BR-LeilaNeural",
            text: "Winfield - Female"
        },
        {
            value: "pt-BR-YaraNeural",
            text: "Zander - Female"
        },
        {
            value: "pt-BR-HumbertoNeural",
            text: "Martina - Male"
        }
    ],
    "pt-PT": [
        {
            value: "pt-PT-FernandaNeural",
            text: "Victor - Female"
        },
        {
            value: "pt-PT-RaquelNeural",
            text: "Demetrius - Female"
        },
        {
            value: "pt-PT-DuarteNeural",
            text: "Eulah - Male"
        }
    ],
    "ro-RO": [
        {
            value: "ro-RO-AlinaNeural",
            text: "Will - Female"
        },
        {
            value: "ro-RO-EmilNeural",
            text: "Jordane - Male"
        }
    ],
    "ru-RU": [
        {
            value: "ru-RU-DariyaNeural",
            text: "Alexzander - Female"
        },
        {
            value: "ru-RU-SvetlanaNeural",
            text: "Jasen - Female"
        },
        {
            value: "ru-RU-DmitryNeural",
            text: "Yvette - Male"
        }
    ],
    "sk-SK": [
        {
            value: "sk-SK-ViktoriaNeural",
            text: "Darrick - Female"
        },
        {
            value: "sk-SK-LukasNeural",
            text: "Shanelle - Male"
        }
    ],
    "sl-SI": [
        {
            value: "sl-SI-PetraNeural",
            text: "Michale - Female"
        },
        {
            value: "sl-SI-RokNeural",
            text: "Jermaine - Male"
        }
    ],
    "es-AR": [
        {
            value: "es-AR-ElenaNeural",
            text: "Kenny - Female"
        },
        {
            value: "es-AR-TomasNeural",
            text: "Berenice - Male"
        }
    ],
    "es-CO": [
        {
            value: "es-CO-SalomeNeural",
            text: "Irwin - Female"
        },
        {
            value: "es-CO-GonzaloNeural",
            text: "Carolanne - Male"
        }
    ],
    "es-MX": [
        {
            value: "es-MX-DaliaNeural",
            text: "Jonas - Female"
        },
        {
            value: "es-MX-JorgeNeural",
            text: "Felicia - Male"
        },
        {
            value: "es-MX-CecilioNeural",
            text: "Virginia - Male"
        },
        {
            value: "es-MX-GerardoNeural",
            text: "Valentina - Male"
        },
        {
            value: "es-MX-LibertoNeural",
            text: "Lynn - Male"
        },
        {
            value: "es-MX-LucianoNeural",
            text: "Nina - Male"
        },
        {
            value: "es-MX-PelayoNeural",
            text: "Christine - Male"
        },
        {
            value: "es-MX-YagoNeural",
            text: "Cathrine - Male"
        },
        {
            value: "es-MX-BeatrizNeural",
            text: "Paul - Female"
        },
        {
            value: "es-MX-CarlotaNeural",
            text: "Trevor - Female"
        },
        {
            value: "es-MX-NuriaNeural",
            text: "Johnpaul - Female"
        },
        {
            value: "es-MX-CandelaNeural",
            text: "Gerhard - Female"
        },
        {
            value: "es-MX-LarissaNeural",
            text: "Ned - Female"
        },
        {
            value: "es-MX-RenataNeural",
            text: "Cornell - Female"
        },
        {
            value: "es-MX-MarinaNeural",
            text: "Frankie - Female"
        }
    ],
    "es-ES": [
        {
            value: "es-ES-ElviraNeural",
            text: "Lowell - Female"
        },
        {
            value: "es-ES-AlvaroNeural",
            text: "Bettye - Male"
        }
    ],
    "es-US": [
        {
            value: "es-US-PalomaNeural",
            text: "Jessie - Female"
        },
        {
            value: "es-US-AlonsoNeural",
            text: "Natasha - Male"
        }
    ],
    "sw-KE": [
        {
            value: "sw-KE-ZuriNeural",
            text: "Hester - Female"
        },
        {
            value: "sw-KE-RafikiNeural",
            text: "Rosemary - Male"
        }
    ],
    "sv-SE": [
        {
            value: "sv-SE-HilleviNeural",
            text: "Carson - Female"
        },
        {
            value: "sv-SE-SofieNeural",
            text: "Oral - Female"
        },
        {
            value: "sv-SE-MattiasNeural",
            text: "Shirley - Male"
        }
    ],
    "ta-IN": [
        {
            value: "ta-IN-PallaviNeural",
            text: "Shawn - Female"
        },
        {
            value: "ta-IN-ValluvarNeural",
            text: "Noemie - Male"
        }
    ],
    "te-IN": [
        {
            value: "te-IN-ShrutiNeural",
            text: "Floy - Female"
        },
        {
            value: "te-IN-MohanNeural",
            text: "Ima - Male"
        }
    ],
    "th-TH": [
        {
            value: "th-TH-AcharaNeural",
            text: "Declan - Female"
        },
        {
            value: "th-TH-PremwadeeNeural",
            text: "Ernie - Female"
        },
        {
            value: "th-TH-NiwatNeural",
            text: "Delfina - Male"
        }
    ],
    "tr-TR": [
        {
            value: "tr-TR-EmelNeural",
            text: "Gardner - Female"
        },
        {
            value: "tr-TR-AhmetNeural",
            text: "Vallie - Male"
        }
    ],
    "uk-UA": [
        {
            value: "uk-UA-PolinaNeural",
            text: "Lavern - Female"
        },
        {
            value: "uk-UA-OstapNeural",
            text: "Rebekah - Male"
        }
    ],
    "ur-PK": [
        {
            value: "ur-PK-UzmaNeural",
            text: "Davion - Female"
        },
        {
            value: "ur-PK-AsadNeural",
            text: "Christa - Male"
        }
    ],
    "vi-VN": [
        {
            value: "vi-VN-HoaiMyNeural",
            text: "Presley - Female"
        },
        {
            value: "vi-VN-NamMinhNeural",
            text: "Lydia - Male"
        }
    ],
    "cy-GB": [
        {
            value: "cy-GB-NiaNeural",
            text: "Lew - Female"
        },
        {
            value: "cy-GB-AledNeural",
            text: "Guiseppe - Female"
        }
    ],
    "af-ZA": [
        {
            value: "af-ZA-AdriNeural",
            text: "Ezekiel - Female"
        },
        {
            value: "af-ZA-WillemNeural",
            text: "Antonette - Male"
        }
    ],
    "am-ET": [
        {
            value: "am-ET-MekdesNeural",
            text: "Deon - Female"
        },
        {
            value: "am-ET-AmehaNeural",
            text: "Neoma - Male"
        }
    ],
    "ar-DZ": [
        {
            value: "ar-DZ-AminaNeural",
            text: "Ismael - Female"
        },
        {
            value: "ar-DZ-IsmaelNeural",
            text: "Mossie - Male"
        }
    ],
    "ar-BH": [
        {
            value: "ar-BH-LailaNeural",
            text: "Toby - Female"
        },
        {
            value: "ar-BH-AliNeural",
            text: "Annamae - Male"
        }
    ],
    "ar-IQ": [
        {
            value: "ar-IQ-RanaNeural",
            text: "Erling - Female"
        },
        {
            value: "ar-IQ-BasselNeural",
            text: "Addie - Male"
        }
    ],
    "ar-JO": [
        {
            value: "ar-JO-SanaNeural",
            text: "Nash - Female"
        },
        {
            value: "ar-JO-TaimNeural",
            text: "Eunice - Male"
        }
    ],
    "ar-KW": [
        {
            value: "ar-KW-NouraNeural",
            text: "Sidney - Female"
        },
        {
            value: "ar-KW-FahedNeural",
            text: "Viviane - Male"
        }
    ],
    "ar-LY": [
        {
            value: "ar-LY-ImanNeural",
            text: "Gianni - Female"
        },
        {
            value: "ar-LY-OmarNeural",
            text: "Linnie - Male"
        }
    ],
    "ar-MA": [
        {
            value: "ar-MA-MounaNeural",
            text: "Candido - Female"
        },
        {
            value: "ar-MA-JamalNeural",
            text: "Jessyca - Male"
        }
    ],
    "ar-QA": [
        {
            value: "ar-QA-AmalNeural",
            text: "Rhiannon - Female"
        },
        {
            value: "ar-QA-MoazNeural",
            text: "Eliane - Male"
        }
    ],
    "ar-SY": [
        {
            value: "ar-SY-AmanyNeural",
            text: "Donnie - Female"
        },
        {
            value: "ar-SY-LaithNeural",
            text: "Norma - Male"
        }
    ],
    "ar-TN": [
        {
            value: "ar-TN-ReemNeural",
            text: "Rey - Female"
        },
        {
            value: "ar-TN-HediNeural",
            text: "Danyka - Male"
        }
    ],
    "ar-AE": [
        {
            value: "ar-AE-FatimaNeural",
            text: "Ryleigh - Female"
        },
        {
            value: "ar-AE-HamdanNeural",
            text: "Constance - Male"
        }
    ],
    "ar-YE": [
        {
            value: "ar-YE-MaryamNeural",
            text: "Jerry - Female"
        },
        {
            value: "ar-YE-SalehNeural",
            text: "Henriette - Male"
        }
    ],
    "bn-BD": [
        {
            value: "bn-BD-NabanitaNeural",
            text: "Aric - Female"
        },
        {
            value: "bn-BD-PradeepNeural",
            text: "Angelina - Male"
        }
    ],
    "my-MM": [
        {
            value: "my-MM-NilarNeural",
            text: "Jasmin - Female"
        },
        {
            value: "my-MM-ThihaNeural",
            text: "Kathryn - Male"
        }
    ],
    "en-KE": [
        {
            value: "en-KE-AsiliaNeural",
            text: "Zack - Female"
        },
        {
            value: "en-KE-ChilembaNeural",
            text: "Lilla - Male"
        }
    ],
    "en-NG": [
        {
            value: "en-NG-EzinneNeural",
            text: "Jules - Female"
        },
        {
            value: "en-NG-AbeoNeural",
            text: "Meggie - Male"
        }
    ],
    "en-TZ": [
        {
            value: "en-TZ-ImaniNeural",
            text: "Zachery - Female"
        },
        {
            value: "en-TZ-ElimuNeural",
            text: "Lila - Male"
        }
    ],
    "fil-PH": [
        {
            value: "fil-PH-BlessicaNeural",
            text: "Stephen - Female"
        },
        {
            value: "fil-PH-AngeloNeural",
            text: "Verla - Male"
        }
    ],
    "gl-ES": [
        {
            value: "gl-ES-SabelaNeural",
            text: "Elton - Female"
        },
        {
            value: "gl-ES-RoiNeural",
            text: "Lelia - Male"
        }
    ],
    "jv-ID": [
        {
            value: "jv-ID-SitiNeural",
            text: "Kieran - Female"
        },
        {
            value: "jv-ID-DimasNeural",
            text: "Carlotta - Male"
        }
    ],
    "km-KH": [
        {
            value: "km-KH-SreymomNeural",
            text: "Adrien - Female"
        },
        {
            value: "km-KH-PisethNeural",
            text: "Destiny - Male"
        }
    ],
    "fa-IR": [
        {
            value: "fa-IR-DilaraNeural",
            text: "Jesse - Female"
        },
        {
            value: "fa-IR-FaridNeural",
            text: "Lauriane - Male"
        }
    ],
    "so-SO": [
        {
            value: "so-SO-UbaxNeural",
            text: "Deron - Female"
        },
        {
            value: "so-SO-MuuseNeural",
            text: "Emmie - Male"
        }
    ],
    "es-BO": [
        {
            value: "es-BO-SofiaNeural",
            text: "Laron - Female"
        },
        {
            value: "es-BO-MarceloNeural",
            text: "Jalyn - Male"
        }
    ],
    "es-CL": [
        {
            value: "es-CL-CatalinaNeural",
            text: "Claud - Female"
        },
        {
            value: "es-CL-LorenzoNeural",
            text: "Etha - Male"
        }
    ],
    "es-CR": [
        {
            value: "es-CR-MariaNeural",
            text: "Milford - Female"
        },
        {
            value: "es-CR-JuanNeural",
            text: "Heaven - Male"
        }
    ],
    "es-CU": [
        {
            value: "es-CU-BelkysNeural",
            text: "Raul - Female"
        },
        {
            value: "es-CU-ManuelNeural",
            text: "Jade - Male"
        }
    ],
    "es-DO": [
        {
            value: "es-DO-RamonaNeural",
            text: "Mauricio - Female"
        },
        {
            value: "es-DO-EmilioNeural",
            text: "Lizzie - Male"
        }
    ],
    "es-EC": [
        {
            value: "es-EC-AndreaNeural",
            text: "Remington - Female"
        },
        {
            value: "es-EC-LuisNeural",
            text: "Marisol - Male"
        }
    ],
    "es-SV": [
        {
            value: "es-SV-LorenaNeural",
            text: "Boyd - Female"
        },
        {
            value: "es-SV-RodrigoNeural",
            text: "Yazmin - Male"
        }
    ],
    "es-GQ": [
        {
            value: "es-GQ-TeresaNeural",
            text: "Austin - Female"
        },
        {
            value: "es-GQ-JavierNeural",
            text: "Dora - Male"
        }
    ],
    "es-GT": [
        {
            value: "es-GT-MartaNeural",
            text: "Javon - Female"
        },
        {
            value: "es-GT-AndresNeural",
            text: "Lucinda - Male"
        }
    ],
    "es-HN": [
        {
            value: "es-HN-KarlaNeural",
            text: "Emile - Female"
        },
        {
            value: "es-HN-CarlosNeural",
            text: "Alisa - Male"
        }
    ],
    "es-NI": [
        {
            value: "es-NI-YolandaNeural",
            text: "Kieran - Female"
        },
        {
            value: "es-NI-FedericoNeural",
            text: "Karina - Male"
        }
    ],
    "es-PA": [
        {
            value: "es-PA-MargaritaNeural",
            text: "Clark - Female"
        },
        {
            value: "es-PA-RobertoNeural",
            text: "Chloe - Male"
        }
    ],
    "es-PY": [
        {
            value: "es-PY-TaniaNeural",
            text: "Sheldon - Female"
        },
        {
            value: "es-PY-MarioNeural",
            text: "Otha - Male"
        }
    ],
    "es-PE": [
        {
            value: "es-PE-CamilaNeural",
            text: "Rolando - Female"
        },
        {
            value: "es-PE-AlexNeural",
            text: "Caitlyn - Male"
        }
    ],
    "es-PR": [
        {
            value: "es-PR-KarinaNeural",
            text: "Johnpaul - Female"
        },
        {
            value: "es-PR-VictorNeural",
            text: "Ofelia - Male"
        }
    ],
    "es-UY": [
        {
            value: "es-UY-ValentinaNeural",
            text: "Jeffrey - Female"
        },
        {
            value: "es-UY-MateoNeural",
            text: "Zelma - Male"
        }
    ],
    "es-VE": [
        {
            value: "es-VE-PaolaNeural",
            text: "Cleveland - Female"
        },
        {
            value: "es-VE-SebastianNeural",
            text: "Lolita - Male"
        }
    ],
    "su-ID": [
        {
            value: "su-ID-TutiNeural",
            text: "Pablo - Female"
        },
        {
            value: "su-ID-JajangNeural",
            text: "Marina - Male"
        }
    ],
    "sw-TZ": [
        {
            value: "sw-TZ-RehemaNeural",
            text: "Neal - Female"
        },
        {
            value: "sw-TZ-DaudiNeural",
            text: "Hulda - Male"
        }
    ],
    "ta-SG": [
        {
            value: "ta-SG-VenbaNeural",
            text: "Eric - Female"
        },
        {
            value: "ta-SG-AnbuNeural",
            text: "Emmanuelle - Male"
        }
    ],
    "ta-LK": [
        {
            value: "ta-LK-SaranyaNeural",
            text: "Derrick - Female"
        },
        {
            value: "ta-LK-KumarNeural",
            text: "Linda - Male"
        }
    ],
    "ur-IN": [
        {
            value: "ur-IN-GulNeural",
            text: "Osvaldo - Female"
        },
        {
            value: "ur-IN-SalmanNeural",
            text: "Jennyfer - Male"
        }
    ],
    "uz-UZ": [
        {
            value: "uz-UZ-MadinaNeural",
            text: "Destin - Female"
        },
        {
            value: "uz-UZ-SardorNeural",
            text: "Reta - Male"
        }
    ],
    "zu-ZA": [
        {
            value: "zu-ZA-ThandoNeural",
            text: "Arely - Female"
        },
        {
            value: "zu-ZA-ThembaNeural",
            text: "Kristina - Male"
        }
    ],
    "bn-IN": [
        {
            value: "bn-IN-TanishaaNeural",
            text: "Emmitt - Female"
        },
        {
            value: "bn-IN-BashkarNeural",
            text: "Kelsie - Male"
        }
    ],
    "is-IS": [
        {
            value: "is-IS-GudrunNeural",
            text: "Gabriel - Female"
        },
        {
            value: "is-IS-GunnarNeural",
            text: "Adrienne - Male"
        }
    ],
    "kn-IN": [
        {
            value: "kn-IN-SapnaNeural",
            text: "Jamie - Female"
        },
        {
            value: "kn-IN-GaganNeural",
            text: "Jaquelin - Male"
        }
    ],
    "kk-KZ": [
        {
            value: "kk-KZ-AigulNeural",
            text: "Bernardo - Female"
        },
        {
            value: "kk-KZ-DauletNeural",
            text: "Jody - Male"
        }
    ],
    "lo-LA": [
        {
            value: "lo-LA-KeomanyNeural",
            text: "Justice - Female"
        },
        {
            value: "lo-LA-ChanthavongNeural",
            text: "Irma - Male"
        }
    ],
    "mk-MK": [
        {
            value: "mk-MK-MarijaNeural",
            text: "Kennith - Female"
        },
        {
            value: "mk-MK-AleksandarNeural",
            text: "Freeda - Male"
        }
    ],
    "ml-IN": [
        {
            value: "ml-IN-SobhanaNeural",
            text: "Mauricio - Female"
        },
        {
            value: "ml-IN-MidhunNeural",
            text: "Modesta - Male"
        }
    ],
    "ps-AF": [
        {
            value: "ps-AF-LatifaNeural",
            text: "Rodger - Female"
        },
        {
            value: "ps-AF-GulNawazNeural",
            text: "Orie - Male"
        }
    ],
    "sr-RS": [
        {
            value: "sr-RS-SophieNeural",
            text: "Sedrick - Female"
        },
        {
            value: "sr-RS-NicholasNeural",
            text: "May - Male"
        }
    ],
    "si-LK": [
        {
            value: "si-LK-ThiliniNeural",
            text: "Lonzo - Female"
        },
        {
            value: "si-LK-SameeraNeural",
            text: "Katrina - Male"
        }
    ],
    "az-AZ": [
        {
            value: "az-AZ-BabekNeural",
            text: "Edna - Male"
        },
        {
            value: "az-AZ-BanuNeural",
            text: "Darius - Female"
        }
    ],
    "ar-LB": [
        {
            value: "ar-LB-RamiNeural",
            text: "Sonia - Male"
        },
        {
            value: "ar-LB-LaylaNeural",
            text: "Cleve - Female"
        }
    ],
    "sq-AL": [
        {
            value: "sq-AL-IlirNeural",
            text: "Bette - Male"
        },
        {
            value: "sq-AL-AnilaNeural",
            text: "Maxine - Female"
        }
    ],
    "ka-GE": [
        {
            value: "ka-GE-GiorgiNeural",
            text: "Lucie - Male"
        },
        {
            value: "ka-GE-EkaNeural",
            text: "Tremayne - Female"
        }
    ],
    "mn-MN": [
        {
            value: "mn-MN-YesuiNeural",
            text: "Antonio - Female"
        },
        {
            value: "mn-MN-BataaNeural",
            text: "Bria - Male"
        }
    ],
    "ne-NP": [
        {
            value: "ne-NP-SagarNeural",
            text: "Karlie - Male"
        },
        {
            value: "ne-NP-HemkalaNeural",
            text: "Dallas - Female"
        }
    ],
    "bs-BA": [
        {
            value: "bs-BA-GoranNeural",
            text: "Adeline - Male"
        },
        {
            value: "bs-BA-VesnaNeural",
            text: "Adalberto - Female"
        }
    ],
    "ar-OM": [
        {
            value: "ar-OM-AbdullahNeural",
            text: "Tiffany - Male"
        },
        {
            value: "ar-OM-AyshaNeural",
            text: "Holden - Female"
        }
    ],
    "ta-MY": [
        {
            value: "ta-MY-SuryaNeural",
            text: "Roxanne - Male"
        },
        {
            value: "ta-MY-KaniNeural",
            text: "Davin - Female"
        }
    ]
}

export const speedOpts = [
    {value: 'x-slow', text: 'Very Slow'},
    {value: 'slow', text: 'Slow'},
    {value: 'medium', text: 'Medium'},
    {value: 'fast', text: 'Fast'},
    {value: 'x-fast', text: 'Very Fast'},
]

export const breakOpts = [
    {value: 1, text: 1},
    {value: 2, text: 2},
    {value: 3, text: 3},
    {value: 4, text: 4},
    {value: 5, text: 5},
]